export const getDate = (date) => {
  if (!date) return "";
  return date.toLocaleString("en-IN", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
};

export const getTime = (date) => {
  if (!date) return "";
  return date.toLocaleString("en-IN", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};
