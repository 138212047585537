import { createContext, useState, useEffect, useContext } from "react";
import ShiftService from "../services/ShiftService";
import UserContext from "./UserContext";
import SaleService from "../services/SaleService";
import CounterService from "../services/CounterService";

const ShiftContext = createContext({});

export function ShiftProvider({ children }) {
  const [shift, setShift] = useState(null);
  const [counters, setCounters] = useState(null);
  const [shiftSales, setShiftSales] = useState(null);
  const [shiftCashInAndOut, setShiftCashInAndOut] = useState(null);
  const { profile } = useContext(UserContext);

  // Load shift from server on load
  useEffect(() => {
    if (!profile?.id) {
      return;
    }
    const _loadShift = async () => {
      const shift = await ShiftService.getOpenShiftForUser(profile.id);
      setShift(shift);
    };
    _loadShift().then();
  }, [profile?.id]);

  useEffect(() => {
    if (!shift?.id) {
      return;
    }
    const _loadShiftSales = async () => {
      const _shiftSales = await SaleService.getSales({
        filter: { shiftId: shift.id },
        limit: 1000,
      });
      setShiftSales(_shiftSales);
    };
    const _loadShiftCashActions = async () => {
      // TODO: Update this dummy cash in and out with corresponding API
      const _shiftCashInAndOuts = [
        {
          id: "abcdefgh123",
          amount: 10,
          createdAt: new Date(),
          description: "Change in",
        },
        {
          id: "abcdefgh456",
          amount: -100,
          createdAt: new Date(Date.now() - 300),
          description: "Freight expense",
        },
      ];
      setShiftCashInAndOut(_shiftCashInAndOuts);
    };

    _loadShiftSales().then();
    _loadShiftCashActions().then();
  }, [shift?.id]);

  useEffect(() => {
    const _loadCounters = async () => {
      const _counters = await CounterService.getCounters();
      setCounters(_counters);
    };
    _loadCounters().then();
  }, []);

  const startShift = async (counter, cashInBox) => {
    const _shift = await ShiftService.openShift({
      userId: profile.id,
      counterId: counter.id,
      openingBalance: cashInBox,
    });
    setShift(_shift);
  };

  const closeShift = async (shift, closingBalance, paymentMethodBalance) => {
    await ShiftService.closeShift(shift.id, {
      closedAt: new Date(),
      closingBalance,
      paymentMethodBalance,
    });
    setShiftSales(null);
    setShiftCashInAndOut(null);
    setShift({});
  };

  const addOrUpdateSaleToShift = (sale) => {
    const _shiftSales = shiftSales || [];
    _shiftSales.push(sale);
    setShiftSales([..._shiftSales]);
  };

  return (
    <ShiftContext.Provider
      value={{
        shift,
        shiftSales,
        shiftCashInAndOut,
        counters,
        startShift,
        closeShift,
        addOrUpdateSaleToShift,
      }}
    >
      {children}
    </ShiftContext.Provider>
  );
}

export default ShiftContext;
