import { BASE_API_URL } from "../constants";
import ApiHelper from "../helpers/ApiHelper";
import CacheHelper from "../helpers/CacheHelper";

const createCategory = async (data) => {
  const url = `${BASE_API_URL}/categories`;
  const resp = await ApiHelper.request({
    url,
    method: "POST",
    data,
    requireAuth: true,
    requireOrg: true,
  });
  _clearCache();
  return resp;
};

const getCategories = async () => {
  const cacheKey = `categories`;
  const cacheValue = CacheHelper.get(cacheKey);
  if (cacheValue) {
    return cacheValue;
  }

  const url = `${BASE_API_URL}/categories`;
  const categories = await ApiHelper.request({
    url,
    method: "GET",
    requireAuth: true,
    requireOrg: true,
  });

  CacheHelper.set(cacheKey, categories);
  categories.forEach((category) => {
    const itemCacheKey = `category-${category.id}`;
    CacheHelper.set(itemCacheKey, category);
  });
  return categories;
};

const getCategory = async (id) => {
  const cacheKey = `category-${id}`;
  const cacheValue = CacheHelper.get(cacheKey);
  if (cacheValue) {
    return cacheValue;
  }

  const url = `${BASE_API_URL}/categories/${id}`;
  const category = await ApiHelper.request({
    url,
    method: "GET",
    requireAuth: true,
    requireOrg: true,
  });
  CacheHelper.set(cacheKey, category);
  return category;
};

const updateCategory = async (id, data) => {
  const url = `${BASE_API_URL}/categories/${id}`;
  const resp = await ApiHelper.request({
    url,
    data,
    method: "PATCH",
    requireAuth: true,
    requireOrg: true,
  });
  _clearCache();
  return resp;
};

const deleteCategory = async (id) => {
  const url = `${BASE_API_URL}/categories/${id}`;
  const resp = await ApiHelper.request({
    url,
    method: "DELETE",
    requireAuth: true,
    requireOrg: true,
  });
  _clearCache();
  return resp;
};

const addCategoryImage = async (categoryId, file) => {
  const formData = new FormData();
  formData.append("file", file);
  const url = `${BASE_API_URL}/categories/${categoryId}/image`;
  const resp = ApiHelper.request({
    url,
    data: formData,
    method: "POST",
    requireAuth: true,
    requireOrg: true,
  });
  _clearCache();
  return resp;
};

const _clearCache = () => {
  CacheHelper.deleteByPrefix("categories");
  CacheHelper.deleteByPrefix("category-");
};

const CategoryService = {
  createCategory,
  getCategories,
  getCategory,
  updateCategory,
  deleteCategory,
  addCategoryImage,
};

export default CategoryService;
