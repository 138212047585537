import { useEffect, useState, useContext, useCallback } from "react";
import { Grid, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CategoryService from "../services/CategoryService";
import ItemService from "../services/ItemService";
import AlertMessageContext from "../contexts/AlertMessageContext";
import OrganizationGuard from "../components/OrganizationGuard";
import ShiftGuard from "../components/ShiftGuard";
import ConsoleLayout from "../components/Layout/ConsoleLayout";
import CheckoutLayout from "../components/CheckoutLayout";
import CategoryCard from "../components/CategoryCard";
import ItemCard from "../components/ItemCard";
import Info from "../components/Alert/Info";
import Loader from "../components/Loader";
import styles from "./PosPage.module.css";

export default function HomePage() {
  return (
    <OrganizationGuard>
      <ShiftGuard>
        <ConsoleLayout>
          <Home />
        </ConsoleLayout>
      </ShiftGuard>
    </OrganizationGuard>
  );
}

function Home() {
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [items, setItems] = useState(null);
  const { postErrorAlert } = useContext(AlertMessageContext);

  const selectedCategory = categories
    ? categories.find((c) => c.id === selectedCategoryId)
    : null;

  const getCategories = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await CategoryService.getCategories();
      setCategories(res);
    } catch (err) {
      postErrorAlert(err.message);
    }
    setIsLoading(false);
  }, [setIsLoading, postErrorAlert]);

  const getItems = useCallback(async () => {
    setIsLoading(true);
    try {
      if (!selectedCategoryId) {
        return;
      }
      const res = await ItemService.getItemsByCategory(selectedCategoryId);
      setItems(res);
    } catch (err) {
      postErrorAlert(err.message);
    }
    setIsLoading(false);
  }, [selectedCategoryId, setIsLoading, postErrorAlert]);

  const onCategoryClicked = (category) => {
    setSelectedCategoryId(category.id);
    setItems(null);
  };

  useEffect(() => {
    getCategories().then();
    getItems().then();
  }, [setIsLoading, getCategories, getItems]);

  useEffect(() => {
    if (!categories || categories.length === 0 || selectedCategory) {
      return;
    }
    setSelectedCategoryId(categories[0].id);
  }, [categories, selectedCategory]);

  return (
    <>
      <Loader isLoading={isLoading} />
      <Grid container>
        <Grid item xs={8} lg={9} className={styles.contentWrapper}>
          <div className={styles.titleBarWrapper}>
            <div className={styles.filterWrapper}>
              <div className={styles.searchBarWrapper}>
                <input
                  type="text"
                  className={styles.searchBar}
                  placeholder="Search"
                />
                <IconButton size="small">
                  <SearchIcon className={styles.searchIcon} />
                </IconButton>
              </div>
            </div>
          </div>
          <div className={styles.categoryWrapper}>
            {categories?.map((category) => (
              <CategoryCard
                key={category.id}
                category={category}
                selectedCategoryId={selectedCategoryId}
                onClick={onCategoryClicked}
              />
            ))}
          </div>
          <div className={styles.itemsWrapper}>
            {items && !items.length ? (
              <Info
                title="No Items Found"
                content="This shop does not have any Items to list"
              />
            ) : (
              items?.map((category) => (
                <ItemCard key={category.id} item={category} />
              ))
            )}
          </div>
        </Grid>
        <Grid item xs={4} lg={3}>
          <div className={styles.summaryWrapper}>
            <CheckoutLayout />
          </div>
        </Grid>
      </Grid>
    </>
  );
}
