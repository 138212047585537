import { createContext, useState } from "react";
import styles from "./PrinterContext.module.css";

const PrinterContext = createContext({});

export function PrinterProvider({ children }) {
  const [printContent, setPrintContent] = useState(null);
  const [callback, setCallback] = useState({});

  const print = async (component) => {
    return new Promise((resolve, reject) => {
      setPrintContent(component);
      setCallback({ completed: resolve, failed: reject });
      setTimeout(() => {
        window.print();
      }, 3);
    });
  };

  window.onafterprint = () => {
    setPrintContent(null);
    callback.completed?.();
    setCallback({});
  };

  return (
    <PrinterContext.Provider value={{ print }}>
      <div className={styles.print}>{printContent}</div>
      <div className={styles.noPrint}>{children}</div>
    </PrinterContext.Provider>
  );
}

export default PrinterContext;
