import { createContext, useState, useContext } from "react";
import { nanoid } from "nanoid";
import { SALE_STATUS } from "../constants";
import PrinterContext from "./PrinterContext";
import ShiftContext from "./ShiftContext";
import InvoiceThreeInch from "../components/Invoice/ThreeInch/InvoiceThreeInch";
import SaleService from "../services/SaleService";

const TEMPORARY_SALE_ID_PREFIX = "temp-";

const SaleContext = createContext({});

export function SaleProvider({ children }) {
  const { print } = useContext(PrinterContext);
  const { shift, addOrUpdateSaleToShift } = useContext(ShiftContext);
  const [sales, setSales] = useState({});
  const [selectedSaleId, setSelectedSaleId] = useState(null);

  const _getSelectedSale = () =>
    selectedSaleId === null ? null : sales[selectedSaleId];

  const _createSaleItem = ({ item, quantity, price, tax }) => {
    const saleItem = {
      itemId: item.id,
      quantity,
      price,
      tax,
    };
    _calculateSaleItemSummary(saleItem);
    return saleItem;
  };

  const _calculateSaleItemSummary = (saleItem) => {
    saleItem.total = saleItem.price * saleItem.quantity;
    saleItem.tax = (saleItem.total * saleItem.tax) / 100;
    saleItem.payable = saleItem.total + saleItem.tax;
  };

  const _calculateSaleSummary = (sale) => {
    let total = 0;
    let tax = 0;
    let payable = 0;
    Object.values(sale.items).forEach((saleItem) => {
      total += saleItem.total;
      tax += saleItem.tax;
      payable += saleItem.payable;
    });
    sale.total = total;
    sale.tax = tax;
    sale.payable = payable;
  };

  const _getNextSaleStatus = (sale) => {
    // if (sale.status == SALE_STATUS.DRAFT) {
    //   if ()
    // }
    // return delivered: if draft and disabled patment and delivery
    // return paid: if draft and disabled patment
    // return printed: if draft and enabled patment and delivery
    return SALE_STATUS.DELIVERED;
  };

  const _updateSale = async (sale) => {
    const saleId = sale.id;
    delete sale.id;
    const _sale = saleId.startsWith(TEMPORARY_SALE_ID_PREFIX)
      ? await SaleService.createSale(sale)
      : await SaleService.updateSale(saleId, sale);

    // Replacing the selected sale id from temporary to server generated
    if (selectedSaleId === saleId) {
      setSelectedSaleId(_sale.id);
    }

    delete sales[saleId];
    sales[_sale.id] = _sale;
    setSales({ ...sales });
    return _sale;
  };

  const _printSaleInvoice = (sale) => {
    return print(<InvoiceThreeInch sale={sale} />);
  };

  const getCurrentSaleItem = (itemId) => {
    return _getSelectedSale()?.items[itemId];
  };

  const addOrUpdateItemToCurrentSale = ({ item, quantity, price }) => {
    const _sale = _getSelectedSale();
    if (!_sale) {
      const _saleItem = _createSaleItem({
        item,
        quantity: quantity ?? 1,
        price: price ?? item.price,
        tax: item.tax || 0,
      });
      const newSale = {
        id: `${TEMPORARY_SALE_ID_PREFIX}${nanoid()}`,
        createdAt: new Date(),
        shiftId: shift.id,
        items: {
          [item.id]: _saleItem,
        },
      };
      _calculateSaleSummary(newSale);
      setSales({ ...sales, [newSale.id]: newSale });
      setSelectedSaleId(newSale.id);
      return;
    }

    const existingSaleItem = _sale.items[item.id];
    if (existingSaleItem) {
      if (quantity <= 0) {
        delete _sale.items[item.id];
      } else {
        existingSaleItem.quantity = quantity ?? existingSaleItem.quantity ?? 1;
        existingSaleItem.price = price ?? existingSaleItem.price ?? item.price;
        _calculateSaleItemSummary(existingSaleItem);
      }
    } else {
      const saleItem = _createSaleItem({
        item,
        quantity: quantity ?? 1,
        price: price ?? item.price,
        tax: item.tax || 0,
      });
      _sale.items = { ..._sale.items, [item.id]: saleItem };
    }
    _calculateSaleSummary(_sale);
    setSales({ ...sales });
  };

  const clearCurrentSale = () => {
    const _sales = { ...sales };
    delete _sales[selectedSaleId];
    setSales(_sales);
  };

  const confirmSale = async (sale, paymentDetails) => {
    const nextStatus = _getNextSaleStatus(sale);
    if (
      nextStatus === SALE_STATUS.PAID ||
      nextStatus === SALE_STATUS.DELIVERED
    ) {
      return paySale(sale, paymentDetails, nextStatus);
    }

    const _sale = await _updateSale({ ...sale, status: nextStatus });
    addOrUpdateSaleToShift(_sale);
    await _printSaleInvoice(_sale);
  };

  // paymentDetails: { cash, card, wallet, discount, totalTendered, balance }
  const paySale = async (sale, paymentDetails, nextStatus) => {
    if (nextStatus === SALE_STATUS.DELIVERED) {
      return deliverSale({ ...sale, paymentDetails });
    }

    const _sale = await _updateSale({
      ...sale,
      paymentDetails,
      status: nextStatus,
    });
    addOrUpdateSaleToShift(_sale);
    await _printSaleInvoice(_sale);
  };

  const deliverSale = async (sale) => {
    const _sale = await _updateSale({ ...sale, status: SALE_STATUS.DELIVERED });
    await _printSaleInvoice(_sale);

    addOrUpdateSaleToShift(_sale);

    // Removing current sale from sales list
    delete sales[_sale.id];
    setSelectedSaleId(Object.keys(sales)[0] || null);
    setSales({ ...sales });
  };

  const sale = _getSelectedSale();
  return (
    <SaleContext.Provider
      value={{
        sales,
        sale,
        getCurrentSaleItem,
        addOrUpdateItemToCurrentSale,
        clearCurrentSale,
        confirmSale,
        paySale,
        deliverSale,
      }}
    >
      {children}
    </SaleContext.Provider>
  );
}

export default SaleContext;
