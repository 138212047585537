import { useEffect, useContext, useCallback } from "react";
import styles from "./styles.module.css";
import TextInput from "../../components/TextInput";
import { useFormik } from "formik";
import * as yup from "yup";
import SelectBox from "../../components/SelectBox";
import SettingService from "../../services/SettingService";
import loadingContext from "../../contexts/loadingContext";
import OrganizationGuard from "../../components/OrganizationGuard";
import ConsoleLayout from "../../components/Layout/ConsoleLayout";

export default function Settings() {
  const { setIsLoading } = useContext(loadingContext);
  const initialValues = {
    shopId: "",
    name: "",
    address: "",
    source: "",
    colorTheme: "",
  };
  const validationSchema = yup.object({
    shopId: yup.string(),
    name: yup.string(),
    address: yup.string(),
    source: yup.string(),
    colorTheme: yup.string(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: submitHandler,
    validateOnChange: false,
  });

  async function submitHandler(value, { setFieldError }) {
    try {
      setIsLoading(true);
      const data = {
        shopName: value.name,
        address: value.address,
        colorTheme: value.colorTheme,
        productSource: value.source,
        yesCloudId: value.shopId,
      };
      await SettingService.addSettings(data);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  }
  const setValues = useCallback((settings) => {
    formik.setValues({
      shopId: settings.yesCloudId,
      name: settings.name,
      address: settings.address,
      source: settings.productSource,
      colorTheme: settings.colorTheme,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getSettingDetails = useCallback(async () => {
    try {
      setIsLoading(true);
      const settings = await SettingService.getSettings();
      setValues(settings);
    } catch (err) {
      console.error(err.message);
    }
    setIsLoading(false);
  }, [setIsLoading, setValues]);
  useEffect(() => {
    getSettingDetails();
  }, [getSettingDetails]);
  return (
    <OrganizationGuard>
      <ConsoleLayout>
        <div className={styles.baseWrapper}>
          <div className={styles.titleBarWrapper}>
            <div className={styles.title}>Settings</div>
          </div>
          <div className={styles.contentWrapper}>
            <div className={styles.inputWrapper}>
              <div className={styles.input}>
                <TextInput
                  name="name"
                  label="Shop Name"
                  placeholder="Type your Shop Name"
                  formikObj={formik}
                />
              </div>
              <div className={styles.input}>
                <TextInput
                  name="source"
                  label="Product source"
                  placeholder="Type your product source"
                  formikObj={formik}
                />
              </div>
              <div className={styles.input}>
                <TextInput
                  name="address"
                  label="Address"
                  placeholder="Type your address"
                  formikObj={formik}
                  multiline={true}
                  rows={2}
                />
              </div>
              <div className={styles.input}>
                <TextInput
                  name="shopId"
                  label="Yescloud Shop ID"
                  placeholder="Type your Shop ID"
                  formikObj={formik}
                />
              </div>
              <div className={styles.input}>
                <SelectBox
                  name="colorTheme"
                  label="Color Theme"
                  placeholder="Select Color Theme"
                  options={["light", "dark"]}
                  formikObj={formik}
                />
              </div>
            </div>
            <div className={styles.btnWrapper}>
              {/* <button className={styles.cancelBtn}>Cancel</button> */}
              <button className={styles.saveBtn} onClick={formik.handleSubmit}>
                Save
              </button>
            </div>
          </div>
        </div>
      </ConsoleLayout>
    </OrganizationGuard>
  );
}
