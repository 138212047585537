import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import styles from "./style.module.css";
import logo from "./logo.png";
import ItemService from "../../services/ItemService";

export default function SaleLayoutItem({ saleItem, onSaleItemClicked }) {
  const [item, setItem] = useState(null);

  const getItemImageSrc = (item) => {
    return item?.images?.[0].url;
  };

  useEffect(() => {
    const _loadItem = async () => {
      const item = await ItemService.getItem(saleItem.itemId);
      setItem(item);
    };
    _loadItem().then();
  }, [saleItem.itemId]);

  return (
    <Grid
      onClick={() => onSaleItemClicked(saleItem)}
      container
      className={styles.saleItem}
      justifyContent="space-between"
    >
      <Grid item lg={3} xs={3} className={styles.productImgGrid}>
        <img src={getItemImageSrc(item) || logo} alt="" />
      </Grid>
      <Grid item lg={9} xs={9} className={styles.productDetail}>
        <h5>{item?.name || ""}</h5>
        <span className={styles.saleItemDescription}>
          {item?.description || ""}
        </span>
        <Grid container alignItems="flex-end">
          <Grid item lg={3} xs={3} className={styles.productQty}>
            <h4>x {saleItem.quantity}</h4>
          </Grid>
          <Grid item lg={9} xs={9} className={styles.productPrice}>
            <h3>₹ {saleItem.total}</h3>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
