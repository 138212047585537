import { useContext, useState, useEffect } from "react";
import {
  Button,
  Grid,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
} from "@mui/material";
import ShiftContext from "../../contexts/ShiftContext";
import { getDate, getTime } from "../../helpers/DateFormatter";
import ShiftGuard from "../ShiftGuard";
import MathUtils from "../../utils/MathUtils";

export default function ShiftDetails() {
  const { shift, shiftSales, shiftCashInAndOut, counters, closeShift } =
    useContext(ShiftContext);

  const [counter, setCounter] = useState(null);
  const [shiftLogs, setShiftLogs] = useState(null);
  const [closingBalance, setClosingBalance] = useState(0);
  const [paymentMethodBalance, setPaymentMethodBalance] = useState(0);

  const onCloseShiftButtonPressed = async () => {
    const resp = window.confirm("Are you sure you wanted to close the shift?");
    if (!resp) {
      return;
    }
    await closeShift(shift, closingBalance, paymentMethodBalance);
  };

  useEffect(() => {
    setCounter(counters?.find((c) => c.id === shift.counterId) || null);
  }, [counters, shift]);

  useEffect(() => {
    const _salesShiftLog = (shiftSales || []).map((sale) => {
      return {
        id: sale.id,
        description: `Sale '${sale.id}'`,
        amountIn: sale.total,
        createdAt: sale.createdAt,
        paymentDetails: sale.paymentDetails,
      };
    });
    const _cashInAndOutShiftLog = (shiftCashInAndOut || []).map(
      (cashInAndOut) => {
        return {
          id: cashInAndOut.id,
          description: cashInAndOut.description,
          amountIn: cashInAndOut.amount >= 0 ? cashInAndOut.amount : null,
          amountOut: cashInAndOut.amount < 0 ? -cashInAndOut.amount : null,
          createdAt: cashInAndOut.createdAt,
          paymentDetails: { cash: cashInAndOut.amount },
        };
      }
    );

    let balance = shift?.openingBalance || 0;
    let cash = balance;
    let card = 0;
    let wallet = 0;
    const _shiftLogs = [..._salesShiftLog, ..._cashInAndOutShiftLog]
      .sort((first, second) => first.createdAt - second.createdAt)
      .map((log) => {
        balance += (log.amountIn || 0) - (log.amountOut || 0);

        // Considering all balance is paid as cash
        cash +=
          (log.paymentDetails.cash || 0) - (log.paymentDetails.balance || 0);
        card += log.paymentDetails.card || 0;
        wallet += log.paymentDetails.wallet || 0;
        return { ...log, balance };
      });

    setShiftLogs(_shiftLogs);
    setClosingBalance(MathUtils.roundToCurrencyPrecision(balance));
    setPaymentMethodBalance({
      cash,
      card,
      wallet,
    });
  }, [shiftSales, shiftCashInAndOut, shift?.openingBalance]);

  const styles = {
    labelText: {
      fontSize: 13,
    },
    boldText: {
      fontWeight: "bold",
      fontSize: 19,
    },
    tableTitleText: {
      fontWeight: "bold",
      fontSize: 17,
    },
  };

  return (
    <ShiftGuard>
      <Box m={2} style={{ height: "100%" }}>
        <Card style={{ padding: 16, marginBottom: 20 }}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={3}>
              <div style={styles.labelText}>Counter</div>
              <div style={styles.boldText}>{counter?.name}</div>
            </Grid>
            <Grid item xs={3}>
              <div style={styles.labelText}>No. of sales</div>
              <div style={styles.boldText}>
                {Object.keys(shiftSales || {}).length}
              </div>
            </Grid>
            <Grid item xs={3}>
              <div style={styles.labelText}>Opening balance</div>
              <div style={styles.boldText}>
                {shift?.openingBalance?.toFixed(2) || 0}
              </div>
            </Grid>
            <Grid item xs={3}>
              <div style={styles.labelText}>Closing balance</div>
              <div style={styles.boldText}>
                {closingBalance?.toFixed(2) || 0}
              </div>
            </Grid>
            <Grid item xs={3}>
              <div style={styles.labelText}>Cash balance</div>
              <div style={styles.boldText}>
                {paymentMethodBalance?.cash?.toFixed(2) || 0}
              </div>
            </Grid>
            <Grid item xs={3}>
              <div style={styles.labelText}>Card balance</div>
              <div style={styles.boldText}>
                {paymentMethodBalance?.card?.toFixed(2) || 0}
              </div>
            </Grid>
            <Grid item xs={3}>
              <div style={styles.labelText}>Wallet balance</div>
              <div style={styles.boldText}>
                {paymentMethodBalance?.wallet?.toFixed(2) || 0}
              </div>
            </Grid>
            <Grid item xs={3}>
              <Button
                color="success"
                variant="contained"
                onClick={onCloseShiftButtonPressed}
              >
                CLOSE SHIFT
              </Button>
            </Grid>
          </Grid>
        </Card>

        <Card style={{ padding: 16, marginBottom: 10 }}>
          <h2>Shift logs</h2>
          <Table aria-label="shift logs table">
            <TableHead>
              <TableRow>
                <TableCell style={styles.tableTitleText}>Date</TableCell>
                <TableCell style={styles.tableTitleText}>Time</TableCell>
                <TableCell style={styles.tableTitleText}>Description</TableCell>
                <TableCell align="right" style={styles.tableTitleText}>
                  Amount in
                </TableCell>
                <TableCell align="right" style={styles.tableTitleText}>
                  Amount out
                </TableCell>
                <TableCell align="right" style={styles.tableTitleText}>
                  Balance
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {shiftLogs &&
                shiftLogs.map((cashAction) => (
                  <TableRow key={cashAction.id}>
                    <TableCell>{getDate(cashAction.createdAt)}</TableCell>
                    <TableCell>{getTime(cashAction.createdAt)}</TableCell>
                    <TableCell component="th" scope="row">
                      {cashAction.description}
                    </TableCell>
                    <TableCell align="right">
                      {cashAction.amountIn?.toFixed(2) || "-"}
                    </TableCell>
                    <TableCell align="right">
                      {cashAction.amountOut?.toFixed(2) || "-"}
                    </TableCell>
                    <TableCell align="right">
                      {cashAction.balance.toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Card>
      </Box>
    </ShiftGuard>
  );
}
