import React, { useContext, useState } from "react";
import { Button, Grid, IconButton } from "@mui/material";
import SaleLayout from "../SaleLayout";
import SaleContext from "../../contexts/SaleContext";
import UserContext from "../../contexts/UserContext";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SalePaymentDialog from "../../dialogs/SalePaymentDialog";
import styles from "./checkout.module.css";

export default function Checkout() {
  const { sale, clearCurrentSale } = useContext(SaleContext);
  const { profile } = useContext(UserContext);
  const [showSalePaymentDialog, setShowSalePaymentDialog] = useState(false);

  return (
    <div className={styles.checkoutContainer}>
      <div className={styles.checkoutContentContainer}>
        <Grid
          container
          className={styles.loggedProfile}
          justifyContent="space-between"
        >
          <Grid item>
            <h5>Manager</h5>
            <h6>
              {profile.firstName} {profile.lastName}
            </h6>
          </Grid>
          <Grid item>
            <IconButton aria-label="delete" size="small">
              <NotificationsIcon fontSize="small" color="inherit" />
            </IconButton>
          </Grid>
        </Grid>

        <div className={styles.saleItemsContainer}>
          <SaleLayout />
        </div>
      </div>

      <div className={styles.checkoutFooterContainer}>
        <Grid
          container
          className={styles.checkoutSummary}
          justifyContent="space-between"
        >
          <Grid item>
            <h5>Sub Total</h5>
            <h6>Tax(10%)</h6>
          </Grid>
          <Grid item>
            <h5>₹ {sale ? sale.total.toFixed(2) : "0.00"}</h5>
            <h6>₹ {sale ? sale.tax.toFixed(2) : "0.00"}</h6>
          </Grid>
        </Grid>
        <Grid
          container
          className={styles.checkoutTotal}
          justifyContent="space-between"
        >
          <Grid item>
            <h3>Total</h3>
          </Grid>
          <Grid item>
            <h3>₹ {sale ? sale.payable.toFixed(2) : "0.00"}</h3>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              className={styles.printBillButton}
              onClick={clearCurrentSale}
              disabled={!sale || !Object.keys(sale.items)}
            >
              CLEAR
            </Button>
          </Grid>
          <Grid item xs={8}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              className={styles.printBillButton}
              onClick={() => {
                setShowSalePaymentDialog(true);
              }}
              disabled={!sale || !Object.keys(sale.items)}
            >
              CONTINUE
            </Button>
          </Grid>
        </Grid>
      </div>

      {showSalePaymentDialog && (
        <SalePaymentDialog
          onClose={() => {
            setShowSalePaymentDialog(false);
          }}
          sale={sale}
        />
      )}
    </div>
  );
}
