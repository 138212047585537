import { BASE_API_URL } from "../constants";
import ApiHelper from "../helpers/ApiHelper";

const getCounters = async () => {
  const url = `${BASE_API_URL}/counters`;
  return ApiHelper.request({
    url,
    method: "GET",
    requireAuth: true,
    requireOrg: true,
  });
};

const CounterService = { getCounters };

export default CounterService;
