import axios from "axios";
import {
  BASE_API_URL,
  DEFAULT_ERROR_MESSAGE,
  STORAGE_KEYS,
} from "../constants";

const getOrganizations = async () => {
  const url = `${BASE_API_URL}/organizations`;
  return request({
    url,
    method: "GET",
    requireAuth: true,
    requireOrg: false,
  });
};

const createOrganization = async (data) => {
  const url = `${BASE_API_URL}/organizations`;
  try {
    const response = await request({
      url,
      method: "POST",
      data,
      requireAuth: true,
      requireOrg: false,
    });

    return { data: response };
  } catch (error) {
    const response = error?.response?.data || {};
    const message = response.message || DEFAULT_ERROR_MESSAGE;
    return { error: message };
  }
};

const getLicences = async () => {
  const url = `${BASE_API_URL}/licences`;
  try {
    const response = await request({
      url,
      method: "GET",
      requireAuth: true,
      requireOrg: true,
    });

    return { data: response };
  } catch (error) {
    const response = error?.response?.data || {};
    const message = response.message || DEFAULT_ERROR_MESSAGE;
    return { error: message };
  }
};

const initiatePayment = async (planId) => {
  const url = `${BASE_API_URL}/payments`;
  try {
    const response = await request({
      url,
      method: "POST",
      data: {
        provider: "paytm",
        planId: planId,
      },
      requireAuth: true,
      requireOrg: true,
    });

    return { data: response };
  } catch (error) {
    const response = error?.response?.data || {};
    const message = response.message || DEFAULT_ERROR_MESSAGE;
    return { error: message };
  }
};

const getSubscriptionPlans = async (productId) => {
  const url = `${BASE_API_URL}/subscription-plans`;
  return request({
    url,
    method: "GET",
    params: { product: productId },
    requireAuth: false,
    requireOrg: false,
  });
};

const getSubscriptionPlan = async (planId) => {
  const url = `${BASE_API_URL}/subscription-plans/${planId}`;
  return request({
    url,
    method: "GET",
    requireAuth: false,
    requireOrg: false,
  });
};

const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN);
  if (!refreshToken) {
    return;
  }

  const url = `${BASE_API_URL}/auth/login-by-refresh-token`;
  const response = await request({
    url,
    method: "POST",
    data: { refreshToken },
  });
  localStorage.setItem(STORAGE_KEYS.ACCESS_TOKEN, response.accessToken);
};

const request = async (option, retry = 0) => {
  if (option.requireAuth && !localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN)) {
    throw new Error(
      "Invalid API call, called API require auth without logging in"
    );
  }

  const selectedOrgId = localStorage.getItem(
    STORAGE_KEYS.SELECTED_ORGANIZATION_ID
  );
  if (option.requireOrg && !selectedOrgId) {
    throw new Error(
      "Invalid API call, called API require organization without selecting organization"
    );
  }

  option.headers = option.headers || {};
  if (option.requireAuth) {
    const accessToken = localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN);
    if (accessToken) {
      option.headers["Authorization"] = accessToken;
    }
  }

  if (option.requireOrg) {
    option.headers["organization-id"] = selectedOrgId;
  }

  try {
    const response = await axios(option);
    return response.data;
  } catch (error) {
    const response = error?.response || {};

    const body = response.data || {};
    if (
      response.status === 401 &&
      body.code !== "ACCESS_TOKEN_EXPIRED" &&
      body.code !== "MISSING_AUTH_TOKEN"
    ) {
      localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN);
      localStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN);
      window.location.reload();
      throw error;
    }

    const isAccessTokenRefreshRequired =
      body.code === "ACCESS_TOKEN_EXPIRED" ||
      body.code === "USER_HAS_NO_PERMISSION" ||
      body.code === "MISSING_AUTH_TOKEN";

    const statusCode = response?.status;
    if (
      !isAccessTokenRefreshRequired &&
      (retry >= 2 || (statusCode && statusCode < 500))
    ) {
      _throwHttpError(error);
    }

    if (isAccessTokenRefreshRequired) {
      localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN);
      await refreshAccessToken();
    }
    // Retrying requests had 500 and access token refresh errors
    return request(option, retry + 1);
  }
};

const _throwHttpError = (error) => {
  const response = error?.response?.data || {};
  const message = response.message || DEFAULT_ERROR_MESSAGE;
  throw new Error(message);
};

const ApiHelper = {
  getOrganizations,
  createOrganization,
  getLicences,
  initiatePayment,
  getSubscriptionPlans,
  getSubscriptionPlan,

  request,
};

export default ApiHelper;
