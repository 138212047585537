// import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
// import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
// import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
// import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import PointOfSaleOutlinedIcon from "@mui/icons-material/PointOfSaleOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PendingActionsOutlinedIcon from "@mui/icons-material/PendingActionsOutlined";
import { ROUTES } from "../../constants";

const navConfig = [
  {
    id: "pos",
    name: "POS",
    path: ROUTES.CONSOLE_POS,
    icon: PointOfSaleOutlinedIcon,
  },
  {
    id: "shift",
    name: "Shift",
    path: ROUTES.CONSOLE_SHIFT,
    icon: PendingActionsOutlinedIcon,
  },
  // {
  //   id: "orders",
  //   name: "Orders",
  //   path: ROUTES.CONSOLE_ORDERS,
  //   icon: ShoppingCartOutlinedIcon,
  // },
  // {
  //   id: "products",
  //   name: "Products",
  //   path: ROUTES.CONSOLE_PRODUCTS,
  //   icon: CategoryOutlinedIcon,
  // },
  // {
  //   name: "Report",
  //   path: "/console/report",
  //   icon: SummarizeOutlinedIcon,
  // },
  {
    id: "settings",
    name: "Settings",
    path: ROUTES.CONSOLE_SETTINGS,
    icon: SettingsOutlinedIcon,
  },
  // Sales
  // Deliveries
];
export default navConfig;
