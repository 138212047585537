import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { ROUTES } from "../constants";
import ShiftContext from "../contexts/ShiftContext";
import Loader from "./Loader";

export default function ShiftGuard({ children }) {
  const { shift, shiftSales, shiftCashInAndOut } = useContext(ShiftContext);

  return !shift ? (
    <Loader isLoading={true} />
  ) : !shift.id ? (
    <Navigate to={ROUTES.CONSOLE_SHIFT} />
  ) : !shiftSales || !shiftCashInAndOut ? (
    <Loader isLoading={true} />
  ) : (
    <>{children}</>
  );
}
