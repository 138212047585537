import React, { useContext, useState, useEffect } from "react";
import styles from "./style.module.css";
import SaleContext from "../../contexts/SaleContext";
import EditSaleItemDialog from "../../dialogs/EditSaleItemDialog";
import SaleLayoutItem from "./SaleLayoutItem";

export default function SaleLayout() {
  const { sale } = useContext(SaleContext);
  const [selectedSaleItem, setSelectedSaleItem] = useState(null);

  const onSaleItemClicked = (saleItem) => {
    setSelectedSaleItem(saleItem);
  };
  const handleSelectedSaleItemClose = () => {
    setSelectedSaleItem(null);
  };

  useEffect(() => {
    if (!selectedSaleItem) {
      return;
    }
    const _saleItem = sale ? sale.items[selectedSaleItem.itemId] : null;
    if (!_saleItem) {
      setSelectedSaleItem(null);
    }
  }, [sale, selectedSaleItem]);

  return (
    <>
      <h3>Sale Items</h3>
      <div className={styles.saleContainer}>
        {sale &&
          Object.keys(sale.items).map((itemId) => (
            <SaleLayoutItem
              key={itemId}
              saleItem={sale.items[itemId]}
              onSaleItemClicked={onSaleItemClicked}
            />
          ))}
      </div>

      {/* Dialogs */}
      {selectedSaleItem && (
        <EditSaleItemDialog
          saleItem={selectedSaleItem}
          handleClose={handleSelectedSaleItemClose}
        />
      )}
    </>
  );
}
