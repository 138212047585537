import { useContext } from "react";
import ConsoleLayout from "../components/Layout/ConsoleLayout";
import ShiftContext from "../contexts/ShiftContext";
import Loader from "../components/Loader";
import ShiftDetails from "../components/ShiftDetails";
import ShiftOpenForm from "../components/ShiftOpenForm";

export default function ShiftPage() {
  return (
    <ConsoleLayout>
      <Shift />
    </ConsoleLayout>
  );
}

function Shift() {
  const { shift } = useContext(ShiftContext);

  if (!shift) {
    return <Loader isLoading={true} size={16} />;
  }

  return <>{shift?.id ? <ShiftDetails /> : <ShiftOpenForm />}</>;
}
