import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import ItemService from "../../../services/ItemService";
import { getDate, getTime } from "../../../helpers/DateFormatter";

export default function InvoiceThreeInch({ sale }) {
  return (
    <Grid container direction="column" justifyContent="flex-start">
      <Grid
        item
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item style={{ fontSize: 26 }}>
          SANOFT
        </Grid>
        <Grid item>SANOFT TECHNOLOGIES</Grid>
        <Grid item>2nd Floor, KKM City Center</Grid>
        <Grid item>Vazhakkad Rd. Jn. Areekode</Grid>
        <Grid item>Malappuram, Kerala</Grid>
      </Grid>
      <Grid
        item
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item>
          BILL NO: <b>12</b>
        </Grid>
        <Grid item>
          DATE: <b>{getDate(sale.createdAt)}</b>
        </Grid>
        <Grid item>
          TIME: <b>{getTime(sale.createdAt)}</b>
        </Grid>
        {sale.customer && (
          <>
            <Grid item>CUSTOMER: {sale.customer.name}</Grid>
            <Grid item>MOBILE: {sale.customer.mobile}</Grid>
          </>
        )}
      </Grid>

      {/* Item details */}
      <Grid
        style={{ marginTop: 20, marginBottom: 20 }}
        item
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={1}>
            SL
          </Grid>
          <Grid item xs={5}>
            ITEM
          </Grid>
          <Grid item xs={2} style={{ textAlign: "right" }}>
            RATE
          </Grid>
          <Grid item xs={1} style={{ textAlign: "right" }}>
            QTY
          </Grid>
          <Grid item xs={1} style={{ textAlign: "right" }}>
            TAX
          </Grid>
          <Grid item xs={2} style={{ textAlign: "right" }}>
            Amount
          </Grid>
        </Grid>
        {Object.keys(sale.items).map((itemId, index) => (
          <InvoiceThreeInchItem
            key={itemId}
            saleItem={sale.items[itemId]}
            index={index}
          />
        ))}
      </Grid>

      {/* Summary */}
      <Grid item container direction="column" alignItems="flex-end">
        <Grid item container justifyContent="flex-end">
          <Grid item xs={3} style={{ textAlign: "right" }}>
            Total:{" "}
          </Grid>
          <Grid item xs={2} style={{ textAlign: "right" }}>
            <b>{sale.total}</b>
          </Grid>
        </Grid>

        <Grid item container justifyContent="flex-end">
          <Grid item xs={3} style={{ textAlign: "right" }}>
            Tax:{" "}
          </Grid>
          <Grid item xs={2} style={{ textAlign: "right" }}>
            <b>{sale.tax}</b>
          </Grid>
        </Grid>

        <Grid item container justifyContent="flex-end">
          <Grid item xs={3} style={{ textAlign: "right" }}>
            Payable:{" "}
          </Grid>
          <Grid item xs={2} style={{ textAlign: "right" }}>
            <b>{sale.payable}</b>
          </Grid>
        </Grid>

        {sale.discount !== 0 && (
          <Grid item container justifyContent="flex-end">
            <Grid item xs={3} style={{ textAlign: "right" }}>
              Discount<sup>+</sup>:{" "}
            </Grid>
            <Grid item xs={2} style={{ textAlign: "right" }}>
              <b>{sale.discount}</b>
            </Grid>
          </Grid>
        )}

        {Object.keys(sale.paymentDetails).length > 0 && (
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            style={{ marginTop: 20 }}
          >
            <Grid item style={{ marginBottom: 5 }}>
              <b>Payment details</b>
            </Grid>
            <Grid item container justifyContent="flex-end">
              <Grid item xs={6} style={{ textAlign: "right" }}>
                Total Tendered:{" "}
              </Grid>
              <Grid item xs={4} style={{ textAlign: "right" }}>
                <b>{sale.paymentDetails.totalTendered}</b>
              </Grid>
            </Grid>
            <Grid item container justifyContent="flex-end">
              <Grid item xs={6} style={{ textAlign: "right" }}>
                Balance:{" "}
              </Grid>
              <Grid item xs={4} style={{ textAlign: "right" }}>
                <b>{sale.paymentDetails.balance}</b>
              </Grid>
            </Grid>
            {Boolean(sale.paymentDetails.cash) && (
              <Grid item container justifyContent="flex-end">
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  Cash:{" "}
                </Grid>
                <Grid item xs={4} style={{ textAlign: "right" }}>
                  <b>{sale.paymentDetails.cash}</b>
                </Grid>
              </Grid>
            )}
            {Boolean(sale.paymentDetails.card) && (
              <Grid item container justifyContent="flex-end">
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  Card:{" "}
                </Grid>
                <Grid item xs={4} style={{ textAlign: "right" }}>
                  <b>{sale.paymentDetails.card}</b>
                </Grid>
              </Grid>
            )}
            {Boolean(sale.paymentDetails.wallet) && (
              <Grid item container justifyContent="flex-end">
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  Wallet:{" "}
                </Grid>
                <Grid item xs={4} style={{ textAlign: "right" }}>
                  <b>{sale.paymentDetails.wallet}</b>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>

      {/* Footer */}
      <Grid
        item
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        style={{ marginTop: 20 }}
      >
        <Grid item>Thank you for shopping</Grid>
        <Grid item>Please visit again</Grid>
      </Grid>
    </Grid>
  );
}

function InvoiceThreeInchItem({ saleItem, index }) {
  const [item, setItem] = useState(null);

  useEffect(() => {
    const _loadItem = async () => {
      const item = await ItemService.getItem(saleItem.itemId);
      setItem(item);
    };
    _loadItem().then();
  }, [saleItem.itemId]);

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs={1}>
        {index + 1}
      </Grid>
      <Grid item xs={5}>
        {item?.name || "---"}
      </Grid>
      <Grid item xs={2} style={{ textAlign: "right" }}>
        {saleItem.price}
      </Grid>
      <Grid item xs={1} style={{ textAlign: "right" }}>
        {saleItem.quantity}
      </Grid>
      <Grid item xs={1} style={{ textAlign: "right" }}>
        {saleItem.tax}
      </Grid>
      <Grid item xs={2} style={{ textAlign: "right" }}>
        {saleItem.total}
      </Grid>
    </Grid>
  );
}
