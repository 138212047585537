import styles from "./styles.module.css";
import brandLogo from "./brand.png";
import * as React from "react";
import { Grid, Button } from "@mui/material";
import { Link } from "react-router-dom";

export default function FormPageLayout(props) {
  const _handleRequestDemo = () => {
    const message =
      "Hello,%0aI%20would%20like%20to%20know%20more%20about%20Sanoft%20POS";
    const mobile = "919746393923";
    window.open(
      `https://api.whatsapp.com/send?phone=${mobile}&text=${message}`
    );
  };
  return (
    <Grid>
      <Grid container className={styles.userWrapper} wrap="nowrap">
        <Grid item className={styles.signupTop}>
          <Button
            style={{ marginRight: 10 }}
            color="inherit"
            onClick={_handleRequestDemo}
          >
            Request Demo
          </Button>
        </Grid>
        <Grid item className={styles.contentContainer} flexDirection="column">
          <img src={brandLogo} className={styles.brandLogo} alt="" />
          {props.children}
        </Grid>
        <Grid item className={styles.privacyPolicy}>
          <h6>
            Copyright @Sanoft 2022 | <Link>Privacy Policy</Link>
          </h6>
        </Grid>
      </Grid>
    </Grid>
  );
}
