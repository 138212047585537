import React, { useContext, useState } from "react";
import { Dialog, Button, Grid } from "@mui/material";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import CashOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import QrCodeScannerOutlinedIcon from "@mui/icons-material/QrCodeScannerOutlined";
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";
import SaleContext from "../../contexts/SaleContext";
import NumberKeypad from "../../components/NumberKeypad";
import styles from "./styles.module.css";

const PAYMENT_MODES = {
  CASH: "cash",
  CARD: "card",
  WALLET: "wallet",
  DISCOUNT: "discount",
};

export default function SalePaymentDialog({ sale, onClose }) {
  const { confirmSale } = useContext(SaleContext);
  const [open, setOpen] = useState(true);
  const [paymentMode, setPaymentMode] = useState(PAYMENT_MODES.CASH);
  const [paymentDetails, setPaymentDetails] = useState({});
  const [discount, setDiscount] = useState(sale.discount || 0);

  const onKeypadChanged = (value) => {
    if (paymentMode === PAYMENT_MODES.DISCOUNT) {
      return setDiscount(value);
    }
    setPaymentDetails({
      ...paymentDetails,
      [paymentMode]: value,
    });
  };

  const totalTendered =
    (paymentDetails.cash || 0) +
    (paymentDetails.card || 0) +
    (paymentDetails.wallet || 0);
  const balance = Number((totalTendered + discount - sale.payable).toFixed(2));

  return (
    <Dialog open={open} onClose={onClose}>
      <div className={styles.titleLayout}>
        <Grid container className={styles.paymentContainer}>
          <Grid item xs={3}>
            <div
              className={`${styles.paymentMethod} ${
                paymentMode === PAYMENT_MODES.CASH &&
                styles.paymentMethodSelected
              }`}
              onClick={() => {
                setPaymentMode(PAYMENT_MODES.CASH);
              }}
            >
              <CashOutlinedIcon className={styles.paymentIcon} />
              Cash
              <span>₹ {paymentDetails.cash || 0}</span>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div
              className={`${styles.paymentMethod} ${
                paymentMode === PAYMENT_MODES.CARD &&
                styles.paymentMethodSelected
              }`}
              onClick={() => {
                setPaymentMode(PAYMENT_MODES.CARD);
              }}
            >
              <PaymentOutlinedIcon className={styles.paymentIcon} />
              Debit Card
              <span>₹ {paymentDetails.card || 0}</span>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div
              className={`${styles.paymentMethod} ${
                paymentMode === PAYMENT_MODES.WALLET &&
                styles.paymentMethodSelected
              }`}
              onClick={() => {
                setPaymentMode(PAYMENT_MODES.WALLET);
              }}
            >
              <QrCodeScannerOutlinedIcon className={styles.paymentIcon} />
              E-Wallet
              <span>₹ {paymentDetails.wallet || 0}</span>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div
              className={`${styles.paymentMethod} ${
                paymentMode === PAYMENT_MODES.DISCOUNT &&
                styles.paymentMethodSelected
              }`}
              onClick={() => {
                setPaymentMode(PAYMENT_MODES.DISCOUNT);
              }}
            >
              <DiscountOutlinedIcon className={styles.paymentIcon} />
              Discount
              <span>₹ {discount}</span>
            </div>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: 20 }}>
          <Grid item xs={3}>
            Payable
          </Grid>
          <Grid item xs={3}>
            ₹ {sale.payable}
          </Grid>

          <Grid item xs={3}>
            <span>Tendered</span>
          </Grid>
          <Grid item xs={3}>
            <span>₹ {totalTendered}</span>
          </Grid>

          <Grid item xs={3}>
            <span>Balance</span>
          </Grid>
          <Grid item xs={3}>
            <span>₹ {balance}</span>
          </Grid>

          <Grid item xs={3}>
            <span>
              Discount<sup>+</sup>
            </span>
          </Grid>
          <Grid item xs={3}>
            <span>₹ {discount}</span>
          </Grid>
        </Grid>
      </div>

      {/* Passing payment methord as key to force reset the key memory value on payment mode change */}
      <NumberKeypad onChange={onKeypadChanged} key={paymentMode} />

      <div className={styles.actionLayout}>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          size="large"
          className={styles.printBillButton}
          disabled={balance < 0}
          onClick={async () => {
            setOpen(false);
            try {
              // TODO: Should spread the discount to the items
              const _paymentDetails = {
                ...paymentDetails,
                balance,
                totalTendered,
              };
              await confirmSale({ ...sale, discount }, _paymentDetails);
              onClose();
            } catch (error) {
              console.error(error);
              setOpen(true);
            }
          }}
        >
          CONFIRM
        </Button>
      </div>
    </Dialog>
  );
}
