import { BASE_API_URL } from "../constants";
import ApiHelper from "../helpers/ApiHelper";

const getOpenShiftForUser = async (userId) => {
  const url = `${BASE_API_URL}/shifts`;
  const shifts = await ApiHelper.request({
    url,
    method: "GET",
    params: { userId, status: "open" },
    requireAuth: true,
    requireOrg: true,
  });
  return shifts && shifts.length ? shifts[0] : {};
};

const closeShift = async (shiftId, data) => {
  const url = `${BASE_API_URL}/shifts/${shiftId}`;
  return ApiHelper.request({
    url,
    method: "PATCH",
    data,
    requireAuth: true,
    requireOrg: true,
  });
};

const openShift = async (data) => {
  const url = `${BASE_API_URL}/shifts/`;
  return ApiHelper.request({
    url,
    method: "POST",
    data,
    requireAuth: true,
    requireOrg: true,
  });
};

const ShiftService = { getOpenShiftForUser, closeShift, openShift };

export default ShiftService;
