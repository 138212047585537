const BackendService = {
  post: () => {
    return {};
  },
  get: () => {
    return {};
  },
  patch: () => {
    return {};
  },
  destroy: () => {
    return {};
  },
};

const getSettings = () => BackendService.get("/settings");
const addSettings = (data) => BackendService.put("/settings", data);

const SettingService = {
  getSettings,
  addSettings,
};

export default SettingService;
