import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { ROUTES } from "../constants";
import UserContext from "../contexts/UserContext";
import Loader from "../components/Loader";

export default function OrganizationGuard({ children }) {
  const { isLoggedIn, organization, organizations } = useContext(UserContext);
  const _isLoggedIn = isLoggedIn();

  if (!_isLoggedIn) {
    return <Navigate to={ROUTES.LOGIN} />;
  }

  if (!organizations) {
    return <Loader isLoading={true} />;
  }

  if (!organization) {
    return <Navigate to={ROUTES.CREATE_ORGANIZATION} />;
  }

  return <>{children}</>;
}
