import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TextInput from "../components/TextInput";
import FormPageLayout from "../components/Layout/FormPageLayout";
import { ROUTES } from "../constants";
import styles from "./LoginPage.module.css";
import Loader from "../components/Loader";
import UserContext from "../contexts/UserContext";
import { Grid, Button } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/Facebook";
import AppleIcon from "@mui/icons-material/Apple";

function LoginForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const { initiateLogin } = useContext(UserContext);
  const navigate = useNavigate();

  async function submitHandler() {
    const _errors = {};
    if (!data.username) {
      _errors.username = "Username is mandatory";
    }
    if (!data.password) {
      _errors.password = "Password is mandatory";
    }
    setErrors(_errors);
    if (Object.keys(_errors).length) {
      return;
    }

    setIsLoading(true);
    try {
      await initiateLogin(data);
      navigate(ROUTES.CONSOLE);
    } catch (err) {
      console.error(err.message);
      setErrors({ ...errors, password: "Username or password is incorrect" });
    }
    setIsLoading(false);
  }

  return (
    <>
      <Grid className={styles.formComponent}>
        <h2 className={styles.title}>Login</h2>
        <p>
          Hey, Enter your details to get sign in
          <br />
          to your account
        </p>
        <TextInput
          className={styles.input}
          label="Username"
          placeholder="Enter username"
          name="username"
          error={errors.username}
          value={data.username}
          size="small"
          variant="outlined"
          onChange={(username) => setData({ ...data, username })}
        />
        <TextInput
          className={styles.input}
          label="Password"
          placeholder="Enter password"
          type="password"
          name="password"
          error={errors.password}
          value={data.password}
          size="small"
          variant="outlined"
          onChange={(password) => setData({ ...data, password })}
        />
        <Link to={ROUTES.FORGOT_PASSWORD} className={styles.forgetPassword}>
          Forgot Password?
        </Link>
        <div>
          <Button
            className={styles.loginBtn}
            onClick={submitHandler}
            type="submit"
            disabled={isLoading}
            fullWidth
          >
            <Loader isLoading={isLoading} />
            Sign in
          </Button>
        </div>
      </Grid>

      <h5 className={styles.textSpacing}>Or Sign in with</h5>
      <Grid container columnSpacing={2} className={styles.signUpDiv}>
        <Grid item>
          <Link className={styles.signUp}>
            <GoogleIcon /> <span>Google</span>
          </Link>
        </Grid>
        <Grid item>
          <Link className={styles.signUp}>
            <FacebookIcon /> <span>Facebook</span>
          </Link>
        </Grid>
        <Grid item>
          <Link className={styles.signUp}>
            <AppleIcon /> <span>Apple</span>
          </Link>
        </Grid>
      </Grid>
      <h6 className={styles.textSpacing}>
        Don’t have an account? <Link to={ROUTES.REGISTER}>Register</Link>
      </h6>
    </>
  );
}

export default function LoginFormPage() {
  return (
    <FormPageLayout>
      <LoginForm />
    </FormPageLayout>
  );
}
