import styles from "./styles.module.css";
import ItemPlaceHolderImage from "./ItemPlaceHolder.png";

export default function CategoryCard({
  category,
  selectedCategoryId,
  onClick,
}) {
  const { name, image } = category;
  return (
    <div
      className={`${styles.cardWrapper} ${
        category.id === selectedCategoryId && styles.cardWrapperActive
      }`}
      onClick={() => {
        if (category.id === selectedCategoryId) {
          return;
        }
        onClick(category);
      }}
    >
      <img
        src={image || ItemPlaceHolderImage}
        alt={name}
        className={styles.image}
      />
      {name && <span className={styles.title}>{name}</span>}
    </div>
  );
}
