import {
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ShiftContext from "../../contexts/ShiftContext";
import { ROUTES } from "../../constants";

export default function ShiftOpenForm() {
  const { counters, startShift } = useContext(ShiftContext);
  const [counter, setCounter] = useState(null);
  const [lastClosedCashInBox, setLastClosedCashInBox] = useState(0);
  const navigate = useNavigate();

  const onCounterSelected = (event) => {
    const counter = counters.find((c) => c.id === event.target.value);
    if (!counter) {
      return;
    }
    setCounter(counter);
    setLastClosedCashInBox(counter.lastClosedCashInBox);
  };

  const onCashBoxBalanceChange = (event) => {
    setLastClosedCashInBox(event.target.value);
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: "100%" }}
    >
      <Grid item xs={12} sm={6} md={4}>
        <Card style={{ padding: 20 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <h1>Shift</h1>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="counter-select-label">Counter</InputLabel>
                <Select
                  labelId="counter-select-label"
                  id="counter-select"
                  label="Counter"
                  fullWidth
                  value={counter?.id || "-"}
                  onChange={onCounterSelected}
                >
                  <MenuItem value="-">---</MenuItem>
                  {counters &&
                    counters.map((_counter) => (
                      <MenuItem value={_counter.id} key={_counter.id}>
                        {_counter.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Cashbox Balance"
                fullWidth
                type="number"
                variant="outlined"
                value={lastClosedCashInBox}
                onChange={onCashBoxBalanceChange}
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button
                color="success"
                variant="contained"
                onClick={() => {
                  startShift(counter, lastClosedCashInBox);
                  navigate(ROUTES.CONSOLE_POS);
                }}
              >
                START SHIFT
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}
