import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import Logo from "./logo.png";
import navConfig from "./navConfig";
import { useLocation, matchPath } from "react-router-dom";
import UserContext from "../../contexts/UserContext";
import { useContext } from "react";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import OrganizationHelper from "../../helpers/OrganizationHelper";

export default function NavBar() {
  const { pathname } = useLocation();
  const { initiateLogout, organization } = useContext(UserContext);

  //To match current url and nav url
  const match = (path) => (path ? matchPath(`${path}/*`, pathname) : false);

  return (
    <div className={styles.baseWrapper}>
      <div className={styles.brandNameWrapper}>
        <img src={Logo} className={styles.logo} alt="" />
      </div>
      {navConfig.map((nav) => {
        const navItemConfig = OrganizationHelper.getPageConfig(
          organization,
          nav.id
        );
        if (!navItemConfig) {
          return <div key={nav.name} />;
        }

        return (
          <Link
            key={nav.name}
            className={`${styles.navLinkWrapper} ${
              match(nav.path) ? styles.activeNavLink : null
            }`}
            to={nav.path}
          >
            <nav.icon className={styles.navLinkIcon} />
            <span className={styles.navLink}>{nav.name}</span>
          </Link>
        );
      })}

      {/*Logout */}
      <Link
        key="logout"
        className={styles.logoutWrapper}
        onClick={initiateLogout}
      >
        <LogoutRoundedIcon className={styles.navLinkIcon} />
        <span className={styles.navLink}>Logout</span>
      </Link>
    </div>
  );
}
