import { BrowserRouter, Routes, Route } from "react-router-dom";
import PosPage from "./pages/PosPage";
import ShiftPage from "./pages/ShiftPage";
import Settings from "./pages/settings";
import LoginPage from "./pages/LoginPage";
import SplashPage from "./pages/SplashPage";
import PageNotFound from "./pages/PageNotFound";
import { LoadingProvider } from "./contexts/loadingContext";
import { AlertMessageProvider } from "./contexts/AlertMessageContext";
import { UserProvider } from "./contexts/UserContext";
import { SaleProvider } from "./contexts/SaleContext";
import { ShiftProvider } from "./contexts/ShiftContext";
import { PrinterProvider } from "./contexts/PrinterContext";
import { ROUTES } from "./constants";
import ConsoleFirstPageRouter from "./components/ConsoleFirstPageRouter";

export default function App() {
  return (
    <PrinterProvider>
      <AlertMessageProvider>
        <UserProvider>
          <ShiftProvider>
            <SaleProvider>
              <LoadingProvider>
                <BrowserRouter>
                  <AppRoutes />
                </BrowserRouter>
              </LoadingProvider>
            </SaleProvider>
          </ShiftProvider>
        </UserProvider>
      </AlertMessageProvider>
    </PrinterProvider>
  );
}

function AppRoutes() {
  return (
    <Routes>
      <Route path={ROUTES.LOGIN} element={<LoginPage />} />
      <Route path={ROUTES.CONSOLE_POS} element={<PosPage />} />
      <Route path={ROUTES.CONSOLE_SHIFT} element={<ShiftPage />} />
      <Route path={ROUTES.CONSOLE_SETTINGS} element={<Settings />} />
      <Route path={ROUTES.CONSOLE} element={<ConsoleFirstPageRouter />} />
      <Route path="/" element={<SplashPage />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
