import React, { useContext, useEffect, useState } from "react";
import { Grid, Button, Tabs, Tab } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import styles from "./styles.module.css";
import SaleContext from "../../contexts/SaleContext";
import NumberKeypad from "../../components/NumberKeypad";
import ItemService from "../../services/ItemService";

const QUANTITY = "quality";
const PRICE = "price";

export default function EditSaleItemDialog({ saleItem, handleClose }) {
  const { addOrUpdateItemToCurrentSale } = useContext(SaleContext);
  const [selectedTab, setSelectedTab] = useState(QUANTITY);
  const [isWaitingForQuantityZeroConfirm, setWaitingForQuantityZeroConfirm] =
    useState(false);
  const [item, setItem] = useState(null);

  const handleTabChange = (_, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    const _loadItem = async () => {
      const item = await ItemService.getItem(saleItem.itemId);
      setItem(item);
    };
    _loadItem().then();
  }, [saleItem.itemId]);

  return (
    <Dialog
      open={Boolean(saleItem && item)}
      onClose={handleClose}
      maxWidth="xs"
    >
      <Grid className={styles.keyWrapper}>
        <Grid container sx={{ p: 2 }}>
          <Grid item xs={9}>
            <h3>{item?.name}</h3>
            <h6>
              {saleItem.quantity} KGS @₹ {saleItem.price}
            </h6>
            <h6>Discount ₹ 0</h6>
          </Grid>
          <Grid item xs={3} sx={{ textAlign: "end" }}>
            <h3>₹ {saleItem.total}</h3>
            <h6>GST ₹ {saleItem.tax}</h6>
          </Grid>
        </Grid>

        <Tabs value={selectedTab} onChange={handleTabChange}>
          <Tab value={QUANTITY} label="Quantity" />
          <Tab value={PRICE} label="Price" />
        </Tabs>

        {selectedTab === QUANTITY && (
          <NumberKeypad
            onChange={(quantity) => {
              if (quantity === 0 && !isWaitingForQuantityZeroConfirm) {
                return setWaitingForQuantityZeroConfirm(true);
              }
              setWaitingForQuantityZeroConfirm(false);
              addOrUpdateItemToCurrentSale({ item, quantity });
              if (quantity === 0) {
                handleClose();
              }
            }}
          />
        )}
        {selectedTab === PRICE && (
          <NumberKeypad
            onChange={(price) => {
              addOrUpdateItemToCurrentSale({ item, price });
            }}
          />
        )}

        <Grid container sx={{ p: 2 }}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="success"
              fullWidth
              onClick={handleClose}
            >
              OK
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}
