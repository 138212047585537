import ApiHelper from "../helpers/ApiHelper";
import { BASE_API_URL } from "../constants";

const createSale = async (data) => {
  const url = `${BASE_API_URL}/sales`;
  const sale = await ApiHelper.request({
    url,
    method: "POST",
    data,
    requireAuth: true,
    requireOrg: true,
  });
  return _format(sale);
};

const updateSale = async (id, data) => {
  const url = `${BASE_API_URL}/sales/${id}`;
  const sale = await ApiHelper.request({
    url,
    method: "PATCH",
    data,
    requireAuth: true,
    requireOrg: true,
  });
  return _format(sale);
};

const getSales = async ({ filter, limit }) => {
  const url = `${BASE_API_URL}/sales`;
  const sales = await ApiHelper.request({
    url,
    method: "GET",
    params: { ...filter, limit },
    requireAuth: true,
    requireOrg: true,
  });
  return sales.map((sale) => _format(sale));
};

const _format = (sale) => {
  sale.createdAt = new Date(sale.createdAt);
  return sale;
};

const SaleService = { createSale, updateSale, getSales };

export default SaleService;
