import styles from "./styles.module.css";
import { useContext } from "react";
import ItemPlaceHolderImage from "./ItemPlaceHolder.png";
import { Grid } from "@mui/material";
import SaleContext from "../../contexts/SaleContext";

export default function ItemCard({ item }) {
  const { addOrUpdateItemToCurrentSale, getCurrentSaleItem } =
    useContext(SaleContext);

  const addToSaleButtonPressed = () => {
    const quantity = (saleItem?.quantity || 0) + 1;
    addOrUpdateItemToCurrentSale({ item, quantity });
  };

  const getImageSrc = (item) => {
    return item?.images?.[0]?.url;
  };

  const saleItem = getCurrentSaleItem(item.id);

  return (
    <>
      <Grid item xs={6} sm={4} lg={3} className={styles.cardWrapper}>
        <Grid
          container
          className={styles.cardItem}
          direction="column"
          justifyContent="space-between"
        >
          <Grid container item>
            <Grid item xs={12} lg={5} alignItems="center">
              <img
                src={getImageSrc(item) || ItemPlaceHolderImage}
                alt={item.name}
                className={styles.productImage}
              />
            </Grid>
            <Grid item xs={12} lg={7}>
              {item.name && <h4 className={styles.title}>{item.name}</h4>}
              {item.description && (
                <span className={styles.itemDescription}>
                  {item.description}
                </span>
              )}
              {<h4 className={styles.price}>₹ {item.price}</h4>}
            </Grid>
          </Grid>

          {item.variants && (
            <Grid container item spacing={1}>
              <Grid item xs={6}>
                <div className={`${styles.variant} ${styles.variantActive}`}>
                  Full
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={styles.variant}>Half</div>
              </Grid>
              <Grid item xs={6}>
                <div className={styles.variant}>Quarter</div>
              </Grid>
            </Grid>
          )}

          <Grid
            container
            item
            className={styles.addToSaleButton}
            justifyContent="space-between"
          >
            <Grid item xs={6} onClick={addToSaleButtonPressed}>
              {saleItem && (
                <span style={{ fontSize: 12 }}>x {saleItem.quantity}</span>
              )}
            </Grid>
            <Grid
              item
              xs={6}
              className={`${styles.variant} ${styles.variantActive}`}
              onClick={addToSaleButtonPressed}
            >
              Add
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
