import React, { useState } from "react";
import { Grid, Button } from "@mui/material";
import { useLongPress } from "use-long-press";
import BackspaceIcon from "@mui/icons-material/Backspace";
import styles from "./style.module.css";

export default function NumberKeypad({ initialValue, onChange }) {
  const [value, setValue] = useState(initialValue ? String(initialValue) : "");

  const updateValue = (_value) => {
    setValue(_value);
    onChange?.(isNaN(_value) ? 0 : Number(_value));
  };

  const appendValue = (char) => {
    const num = value + char;
    if (isNaN(num)) {
      return;
    }
    updateValue(num);
  };

  const backspace = () => {
    if (skipClick) {
      return setSkipClick(false);
    }
    const _value = value.length ? value.substring(0, value.length - 1) : "";
    updateValue(_value);
  };

  // Hack to prevent click handler triggerring on long click
  const [skipClick, setSkipClick] = useState(false);
  const longPressHandler = useLongPress(() => {
    setSkipClick(true);
    updateValue("");
  }, 1000)();

  return (
    <Grid container spacing={1.5} sx={{ p: 2 }} className={styles.keypad}>
      <Grid item xs={4}>
        <Button
          variant="contained"
          size="large"
          fullWidth
          color="inherit"
          onClick={() => appendValue("1")}
        >
          1
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button
          variant="contained"
          size="large"
          fullWidth
          color="inherit"
          onClick={() => appendValue("2")}
        >
          2
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button
          variant="contained"
          size="large"
          fullWidth
          color="inherit"
          onClick={() => appendValue("3")}
        >
          3
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button
          variant="contained"
          size="large"
          fullWidth
          color="inherit"
          onClick={() => appendValue("4")}
        >
          4
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button
          variant="contained"
          size="large"
          fullWidth
          color="inherit"
          onClick={() => appendValue("5")}
        >
          5
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button
          variant="contained"
          size="large"
          fullWidth
          color="inherit"
          onClick={() => appendValue("6")}
        >
          6
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button
          variant="contained"
          size="large"
          fullWidth
          color="inherit"
          onClick={() => appendValue("7")}
        >
          7
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button
          variant="contained"
          size="large"
          fullWidth
          color="inherit"
          onClick={() => appendValue("8")}
        >
          8
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button
          variant="contained"
          size="large"
          fullWidth
          color="inherit"
          onClick={() => appendValue("9")}
        >
          9
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button
          variant="contained"
          size="large"
          fullWidth
          color="inherit"
          onClick={backspace}
          {...longPressHandler}
        >
          <BackspaceIcon />
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button
          variant="contained"
          size="large"
          fullWidth
          color="inherit"
          onClick={() => appendValue("0")}
        >
          0
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button
          variant="contained"
          size="large"
          fullWidth
          color="inherit"
          onClick={() => appendValue(".")}
        >
          .
        </Button>
      </Grid>
    </Grid>
  );
}
