import TextField from "@mui/material/TextField";

export default function TextInput({
  name,
  label,
  error,
  value,
  onChange,
  ...props
}) {
  const _onChange = (event) => {
    onChange(event.target.value);
  };
  return (
    <TextField
      id="standard-basic"
      label={label ? label : name}
      variant="standard"
      type="text"
      fullWidth
      sx={{ mb: 3 }}
      onChange={_onChange}
      value={value || ""}
      error={Boolean(error)}
      helperText={error || null}
      {...props}
    />
  );
}
